footer {
    z-index: 100;
}

.social-links {
    & a {
        font-size: 40px;
        color: var(--bs-warning);
        margin-right: 8px;
    }
    & a:hover {
        //background: var(--bs-warning);
        color: var(--bs-info);
    }
}
