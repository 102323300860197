//$nav-height:10%;
$primary: #165064; //turquoise simplifipro plaquette #175366
$info: #28b9c4;  // cyan simplifipro plaquette #2ab9c4
$warning: #fbb03f; // yellow simplifipro plaquette : #fbc01b
.maxheight{
     height: 100vh; /* Fallback for browsers that do not support Custom Properties */
    height: var(--vh);
}
@import "variables";
@import "navbar";
@import "hero";
@import "footer";
@import "../node_modules/animate-sass/animate";
@import "bootstrap-icons";
@import "media";
