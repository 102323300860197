////laptop ratio
//
//@media screen
//  and (min-device-height: 750px)
//  and (max-device-height: 850px)
//  and (-webkit-min-device-pixel-ratio: 1) {
//    #offre_modal {
//         & img {
//             margin: auto;
//            max-width: 700px!important;
//            max-height: 700px!important;
//        }
//    }
//}
//
//@media screen
//  and (min-device-height: 650px)
//  and (max-device-height: 750px)
//  and (-webkit-min-device-pixel-ratio: 1) {
//    #offre_modal {
//         & img {
//             margin: auto;
//            max-width: 600px!important;
//            max-height: 600px!important;
//        }
//    }
//}
//
//@media screen
//  and (min-device-height: 550px)
//  and (max-device-height: 650px)
//  and (-webkit-min-device-pixel-ratio: 1) {
//    #offre_modal {
//         & img {
//             margin: auto;
//            max-width: 600px!important;
//            max-height: 600px!important;
//        }
//    }
//}
//
//@media screen
//  and (min-device-height: 450px)
//  and (max-device-height: 550px)
//  and (-webkit-min-device-pixel-ratio: 1) {
//    #offre_modal {
//         & img {
//             margin: auto;
//            max-width: 500px!important;
//            max-height: 500px!important;
//        }
//    }
//}
//
//@media screen
//  and (min-device-height: 750px)
//  and (max-device-height: 950px)
//  and (-webkit-min-device-pixel-ratio: 1) {
//    #offre_modal {
//         & img {
//             margin: auto;
//            max-width: 800px!important;
//            max-height: 800px!important;
//        }
//    }
//}
//
//@media screen
//  and (min-device-height: 750px)
//  and (max-device-height: 950px)
//  and (-webkit-min-device-pixel-ratio: 1) {
//    #offre_modal {
//         & img {
//             margin: auto;
//            max-width: 800px!important;
//            max-height: 800px!important;
//        }
//    }
//}

    #offre_modal {
         & img {
             margin: auto;
             max-width: 80vh!important;
             max-height: 80vh!important;
        }
    }

@media screen
  and (min-device-width: 1200px)
  and (max-device-width: 1600px)
  and (-webkit-min-device-pixel-ratio: 1) {
    #offre_modal {
        & .modal-dialog {
            max-width: 60% !important;
        }
    }
}

@media screen
  and (min-device-width: 300px)
  and (max-device-width: 419px)
  and (-webkit-min-device-pixel-ratio: 1) {
    h1 {
        font-size: 1.5rem!important;
    };
    h2 {
        font-size: 1.3rem!important;
    };
}
