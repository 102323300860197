
.hero {
    position: relative;
    & #accroche{
        height: 45%;
    }
    & #choix{
        height: 55%;
        align-self: start;
    }
  & > * {
    z-index: 100;
  }
  background: url("../img/jpg/RectoJPG.jpg") center;
  background-size: cover;


  & .btn-menu, .btn-book {
    color: var(--bs-warning);
    padding: 10px 10px;
    border: 2px solid var(--bs-warning);
    border-radius: 40px;
      font-weight: bold;
      min-width: 100px;
      max-width: 150px;

  }

  &::after {
    content: "";
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }

  & .btn-menu:hover, .btn-book:hover {
    background: var(--bs-warning);
    color: #ffffff;
  }

    & .box-overlay {
        background: transparentize($primary,0.5);
        border-radius: 40px;
    }


}


//@media only screen and (max-width: 768px) {
// .modal{
//     height: 90%!important;
// }
//}
