.navbar{
    z-index: 100;
    & .info {
        & >a{
          color: var(--bs-warning)!important;
        }
        & > p {
            &.important {
                font-size: 1rem;
                font-weight: bold;
            }

            margin-bottom: 0;
        }
}

}


@media (max-width:345px) {
    .navbar{
    & .info {
    text-align: center;
  }
        & > p {
            &.important {
                font-size: 1rem;
                font-weight: bold;
            }

            margin-bottom: 0;
        }
}

}
