@font-face {
  font-family: "bootstrap-icons";
  src: url("../fonts/bootstrap-icons/bootstrap-icons.woff2?231ce25e89ab5804f9a6c427b8d325c9") format("woff2"),
url("../fonts/bootstrap-icons/bootstrap-icons.woff?8bd4575acf83c7696dc7a14a966660a3") format("woff");
}


[class^="bi-"]::before,
[class*=" bi-"]::before {
  display: inline-block;
  font-family: bootstrap-icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  vertical-align: -.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.bi-facebook::before { content: "\f344"; }
.bi-instagram::before { content: "\f437"; }
.bi-linkedin::before { content: "\f472"; }
.bi-envelope::before { content: "\f32f"; }
.bi-phone::before { content: "\f4e7"; }
.bi-google::before { content: "\f3f0"; }
